//-------- COLORS --------
// usage:
// import {color} from "../../styles/styled/general"; (where you want to import)
// color:${color.pink}

export const color = {
    green: "#19cf95",
    darkGreen: "#17ba87",
    lightTeal: "#1AAFD0",
    grey: `#d9d9d9`,
    lightGrey: `#f9f9f9`,
    darkGrey: `#0e0d0d`,
    bodyColor: `#5a5a5a`,
    darkTeal: `#001130`,
}

export const bp = {
    xxs: "36.250em",
    xs: "47.938em",
    sm: "52.500em",
    md: "61.938em",
    lg: "80em",
}

export const btnLink = {
    color: "#0e0d0d",
    borderRadius: "5px",
    padding: "10px 15px",
    letterSpacing: "0.45px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: "600",
    lineHeight: "1rem",

}

export const btnBlue = {
    fontWeight: "bold",
    fontSize: ".9rem",
    padding: "5px 25px 5px 0",
    color: "#1AAFD0",
    letterSpacing: ".6px",
    textDecoration: "none",
    lineHeight: "1",
    display: "inline-block",
}

export const btnBlack = {
    fontWeight: "bold",
    fontSize: "1rem",
    padding: "1.2rem 2rem",
    color: "#0e0d0d",
    border: "2px solid #0e0d0d",
    borderRadius: "0.5rem",
    letterSpacing: ".6px",
    textDecoration: "none",
    lineHeight: "1",
    display: "inline-block",
}

export const srOnly = {
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: 0,
    overflow: "hidden",
    clip: "rect(0,0,0,0)",
    whiteSpace: "nowrap",
    webkitClipPath: "inset(50%)",
    clipPath: "inset(50%)",
    border: 0,
}
export const load = {
    border: "2px solid red",
}


