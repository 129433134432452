import React from "react";
import styled from "styled-components";
import {color} from "../Styles/styled/general";
import {row, col} from "../Styles/styled/grid"

const Footer = styled.footer `
    padding: 15px 0;
    width:100%;
    background: ${color.darkGrey};
    margin-top: 4rem;
    .container {
        max-width:1240px;
        margin: auto;
    }
    p, a {
        font-size:.8rem;
        color: ${color.grey};
        letter-spacing: .5px;
        @media all and (max-width:380px) {
            font-size:.75rem;
        }
    }
`;
const Row = styled.div `
    ${row};
`;
const Column = styled.div `
    ${col};
    &:last-child {text-align: right;}
`;
const MainFooter = ()=> {
    return(
        <Footer>
            <div className="container">
                <Row>
                    <Column>
                        <p>&copy; 2022 - El Blog de Viajes</p>
                    </Column>
                    <Column>
                        <p>By <a href="https://www.elblogdeviajes.com" rel="noreferrer" target="_blank">El Blog de Viajes</a></p>
                    </Column>
                </Row>
            </div>
        </Footer>
    )
}
export default MainFooter;