import React from "react";
import styled from "styled-components";
import {bp, btnBlue} from "../Styles/styled/general";
import arrowblue from "../assets/icons/arrow-blue.svg"


const Card = styled.div `
    box-shadow: 0 0 0.675rem 0.075rem rgb(0 0 0 / 20%);
    background-color: #fff;
`;

const ImageWrapper = styled.a `
        width: 100%;
        overflow: hidden;
        display: inline-block;
         img {
        max-width:100%;
        vertical-align:bottom;
        &:hover {opacity: .8}
    }
`;
const TextWrapper = styled.div `
    padding: 0 10px 15px 10px;
     @media all and (min-width:${bp.md}) {
            padding: 0 15px 15px 15px;
        }
     h3 {
        font-size: .8rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
        font-size:1rem;
          @media all and (min-width:${bp.md}) {
            font-size:1.1rem
        }
    }
`;


const ButtonBlue = styled.a `
    ${btnBlue};
    background: transparent url(${arrowblue}) no-repeat 97% 6px / 18px;
    &:hover {
        background-position: 100% 6px;
    }
`;

const CardResult = ({title, link, image}) => {
    return (
        <Card>
            <ImageWrapper target="_blank" href={link}><img src={image} alt={title}/></ImageWrapper>
            <TextWrapper>
                <h3>{title}</h3>
                <ButtonBlue target="_blank" href={link}>Ver mas</ButtonBlue>
            </TextWrapper>
        </Card>
    )
}

export default CardResult