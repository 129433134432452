import React from "react";
import styled from "styled-components";
import {color, bp} from "../Styles/styled/general";
import ReactGA from 'react-ga';

const SectionNav = styled.nav `
    width: 100%;
    height: 50px;
    position: fixed;
    top: -100%;
    background:#fff;
    z-index: 3;
        transition: 0.5s all ease-in-out;
    box-shadow: 0px 1px 8px 2px rgb(184 182 182 / 65%);
    .scrolling & {top: 0}
    ul {
        display: flex;
        justify-content: center;
        padding: 0;
            
        li {
            display: inline-flex;
            font-size:14px;
            @media all and (max-width: ${bp.xxs}) {
                font-size:12px;
            }
        }
        a {
            color: ${color.darkGrey};
            text-decoration: none;
            padding: 15px 20px;
            @media all and (max-width: ${bp.xxs}) {
                padding: 15px 5px;
            }
        }
    }
`;
ReactGA.initialize("UA-30222487-1");
const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    })
}

const NavFix = ()=> {
    return  (
        <SectionNav>
            <ul>
                <li><a href="#actividades" onClick={eventTrack.bind(this, "Loop Actividades Menu", "Loop: Actividades Menu", "Loop Menu Actividades")}>Actividades</a></li>
                <li><a href="#tours" onClick={eventTrack.bind(this, "Loop Tours Menu", "Loop: Tours Menu", "Loop Menu Tours")}>Tours</a></li>
                <li><a href="#hoteles" onClick={eventTrack.bind(this, "Loop Hoteles Menu", "Loop: Hoteles Menu", "Loop Menu")}>Hoteles</a></li>
                <li><a href="#vuelos" onClick={eventTrack.bind(this, "Loop Vuelos Menu", "Loop: Vuelos Menu", "Loop Menu Vuelos")}>Vuelos</a></li>
                <li><a href="#coches" onClick={eventTrack.bind(this, "Loop Coches Menu", "Loop: Coches Menu", "Loop Menu Coches")}>Coches</a></li>
                <li><a href="#posts" onClick={eventTrack.bind(this, "Loop Posts Menu", "Loop: Posts Menu", "Loop Menu Posts")}>Posts</a></li>
            </ul>
        </SectionNav>
    )
}
export default NavFix;
