import React from "react";
import styled from "styled-components";
import {bp, btnBlack} from "../Styles/styled/general";
import {row, col} from "../Styles/styled/grid";
import Car from "../assets/images/car.jpg";
import Flight from "../assets/images/flight.jpg";

const Row = styled.div `
    ${row};
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    align-items: center;
    &:first-child .column-text {order: 1}
    &:last-child .column-text {order: 1}
    
    @media all and (min-width: ${bp.xs}) {
    &:first-child .column-text {padding-left: 2rem}
    &:last-child {
        .column-text {padding-right: 2rem; order: 1}
        .column-image {order: 2}
    }
    }
`;
const Column = styled.div `
    ${col};
    max-width:100%;
    flex-basis:100%;
    @media all and (min-width: ${bp.md}) {
        max-width:50%;
        flex-basis:50%;
    }
    h2 {
        font-size: 2rem;
        line-height: 1.3;
        margin-bottom: 1rem;
        @media all and (max-width: ${bp.xs}) {font-size:1.6rem}
    }
    p {
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 1.4rem;
    }
    img {width:100%}
   
    @media all and (max-width: ${bp.xs}) {font-size:1.6rem}
`;
const Button = styled.a `
    ${btnBlack};
    @media all and (max-width: ${bp.xs}) {
            font-size: .9rem;
            padding: 1rem;
    }
`;
const Capitalize = styled.span `text-transform: capitalize;`;

const FlightCars = ({destination})=> {
    return (
        <div className="container">
            <Row id="vuelos">
                <Column className="column-image">
                    <img src={Flight} alt="Reserva un vuelo"/>
                </Column>
                <Column className="column-text">
                    <h2>Ya tienes vuelo para <Capitalize>{destination}</Capitalize>?</h2>
                    <p>
                        Si aún no tienes vuelo para <Capitalize>{destination}</Capitalize>, Skyscanner siempre te muestra los mejores precios.
                        Después tu ya decides dónde compras el billete.
                    </p>
                    <p>
                        <Button href="https://skyscanner.pxf.io/c/2933279/1027991/13416?associateid=AFF_TRA_19354_00001" target="_blank">Mira más vuelos aquí</Button>
                    </p>
                </Column>
            </Row>
            <Row id="coches">
                <Column className="column-text">
                    <h2>Dónde alquilar un coche en <Capitalize>{destination}</Capitalize>?</h2>
                    <p>
                        Si vas a viajar a <Capitalize>{destination}</Capitalize> y quieres alquilar un coche mira los precios de todas las empresas aquí.
                        Este se ha convertido en el buscador de coches de alquiler más barato del mercado.
                    </p>
                    <p>
                        <Button href="https://www.skyscanner.net/g/referrals/v1/cars/home?_ga=2.35067618.231407317.1637184703-394666746.1626985038&adId=1100662&campaignId=13416&currency=EUR&locale=es-ES&market=ES&mediaPartnerId=2933279" target="_blank">Mira los precios</Button>
                    </p>
                </Column>
                <Column className="column-image">
                    <img src={Car} alt="Alquila un coche"/>
                </Column>
            </Row>

        </div>
    )
}
export default FlightCars;