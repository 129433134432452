import styled from "styled-components";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const ReactLoaderSpinner = ({ type, color, isLoading }) => {
  //other logic
  return (
    <SpinnerWrapper>
      {isLoading && 
        <Loader
          type="Oval"
          color="#00BFFF"
          height={100}
          width={100}
        />
      }
    </SpinnerWrapper>
  );
}

export default ReactLoaderSpinner;

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 47%;
  top: 40%;
  z-index: 20;
`;