import React from "react";
import styled from "styled-components";
import {col, row} from "../Styles/styled/grid";
import {bp} from "../Styles/styled/general";

import DefaultBanner from "../assets/images/404b.jpg";

const Row = styled.div `
    ${row}`
;
const Col = styled.div `
    ${col}`
;
const Banner = styled.section `
    width: 100%;
    height: 70vh;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    min-height: 350px;
    @media all and (max-width:${bp.xs}) {
        height:300px;
            min-height: 300px;
        }
    &:after {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    content: '';
    background: rgba(0, 0, 0, 0.45)
    }
    ul {display: none}
    
`;
const BannerContent = styled.section `
    width: 100%; 
    position: absolute;
    bottom: 50px;
    z-index: 2;
    @media all and (max-width:${bp.xs}) {
        bottom: 20px;
    }
   
    h1 {
        color: #fff;
        font-size: 3.3em;
        max-width:680px;
        text-shadow: 1px 2px 4px #272727;
        margin-bottom: .5em;
        @media all and (max-width:${bp.xs}) {
            font-size:2.3em;
        }
    }
    p {
        color:#fff;
        font-size:1.25em;
        letter-spacing: 0.6px;
        max-width:680px;
        text-shadow: 1px 2px 1px #272727;
        @media all and (max-width:${bp.xs}) {
            font-size:1em;
        }
`;

const TopBanner = ({title, description, image, imageAlt, aventuraTitle}) => {
    return (
        <Banner style={{ backgroundImage: `url(${image || DefaultBanner})` }}>
            <BannerContent>
                <div className="container">
                    <Row>
                        <Col>
                            <h1>{title}</h1>
                            <p>{description}</p>
                        </Col>
                    </Row>
                </div>
            </BannerContent>
            <ul>
                <li>{aventuraTitle}</li>
            </ul>
        </Banner>
    )
}

export default TopBanner;