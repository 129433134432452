import React from "react";
import styled from "styled-components";
import {color, bp} from "../Styles/styled/general";
import logo from "./../assets/logos/ebdv-icon.svg"


let mainBody = document.querySelector('body');


// OnScroll event handler
const isScrolling = () => {
    const scroll = document.documentElement.scrollTop

    // Add/FRemove class if scroll more than (value)
    if (scroll > 250) {
        mainBody.classList.add("scrolling");
    } else {
        mainBody.classList.remove("scrolling")
    }
}

// Use either onScroll or throttledOnScroll
window.addEventListener('scroll', isScrolling);

const Mainheader = styled.header `
    position: fixed;
    width: 100%;
    z-index: 3;
    padding: 10px;
    .container {
        max-width:1080px;
    }
`;
const Logo = styled.a `
    display: flex;
    align-items: center;
    text-decoration:none;
    p {
        color: #fff;
        font-size:1.9rem;
        font-weight:900;
        padding-left: 10px;
        line-height: 1;
        span.oo {
            letter-spacing: -2px;
            letter-spacing: -3px;
            padding-right: 3px;
        }
        span.by {
            clear: both;
            display: block;
            font-weight: 100;
            font-size: .7rem;
        }
        @media all and (max-width:${bp.xxs}) {
        font-size:1.2rem;
        span.by {font-size: .5rem;}
        }
    }
    .scrolling & {
        opacity: 0;
        transition: 0.3s all ease-in-out;
    }
`;
const Logoimage = styled.div `
    padding: 8px;
    width:40px;
    height: 40px;
    background: ${color.green} url("${logo}") no-repeat center center / 22px;
    border-radius:100%;
    @media all and (max-width:${bp.xxs}) {
        width:35px;
        height: 35px;
        background-size:20px;
        }
 
`;


const Header = ()=> {
    return (
        <Mainheader>
        <div className="container">
            <Logo href="/">
                <Logoimage />
                <p>L<span className="oo">OO</span>P <span className="by">by El Blog de Viajes</span></p>
            </Logo>
        </div>
        </Mainheader>
    )
}

export default Header;