
export const row = {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '-.625em',
    marginLeft: '-.625em'
    }

export const col = {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: '100%',
    paddingRight: '.625em',
    paddingLeft: '.625em',
    }

