import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Prismic from '@prismicio/client'
import styled from "styled-components";
import defaultimage from "../assets/images/404a.jpg";
import { RichText } from "prismic-reactjs";
import { client } from '../utils/prismicHelpers';
import TopBanner from "./TopBanner";
import CardResult from "./ResultCard";
import BlogItem from "./../Blog/BlogItem";
import FlightCars from "./FlightCars";
import {color, bp, btnBlack} from "../Styles/styled/general";
import {col, row} from "../Styles/styled/grid";
import ReactLoaderSpinner from "../components/Loader";
import MainFooter from "../components/Footer";
import MetaTags from 'react-meta-tags';
import NavFix from "../components/NavFix";


const SearchResultsContainer = styled.div `
    width: 100%;
    .row-wrapper {
        padding-top: 5rem;
        padding-bottom: 5rem;
        @media all and (max-width:${bp.md}) {
            padding-top: 3rem;
        padding-bottom: 3rem;
        }
        }
`;

const Rowfull = styled.div`
    ${row};
      h2 {
        margin-top: .5em;
        margin-bottom: 1.5em;
        text-align:center;
            font-size: 1.4rem;
        @media all and (min-width:${bp.md}) {
            font-size:2rem
        }
    }
    .column50 {
        @media all and (max-width:${bp.xxs}) {
            flex-basis:100%;
            &:first-child {padding-bottom: 20px;}
        }
    }
`;

const Columnfull = styled.div`
    ${col}
`;

const Rowscroll = styled.div `
    ${row}
    @media all and (max-width:${bp.xs}) {
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 20px;
        }
`;

const Column = styled.div `
    ${col}
    @media all and (max-width:${bp.xs}) {
        width:420px;
        max-width:420px;
        flex-basis:420px;
        flex: 0 0 auto;
    }
    @media all and (min-width:${bp.xs}) {
        flex-basis:100%;
        flex-basis:33.3333%;
    }
    
`;

const Morelink = styled.div `
    text-align: center;
    margin-top: 3rem;
    p {font-size:1.2rem; font-weight: 400}
    a {
        ${btnBlack};
        :hover {
            background: ${color.darkGrey};
            color:#fff;
        }
        &.btn-link {
        padding: 0;
        border: 0;
        text-decoration: underline;
    }
`;

const BlogWrapper = styled.div`
    margin-top: 24px;
    padding-left: .625em;
    padding-right: .625em;
    width:100%;
    max-width:1220px;
    margin: auto;
`;

const SearchResults = (props) => {
    const location = useLocation();
    const history = useHistory();

    const [filterParams, setFilterParams] = useState(null);
    const [posts, setPosts] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (location.state) {
            const params = location.state.params;
            setFilterParams(params);

            getPosts(params);
        } else {
            history.push('/');
        }
    }, []);

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    const getPosts = async (searchParams) => {
        // Get Blogs from Wordpress endpoint by search target
        const response = await fetch(
            `https://elblogdeviajes.com/wp-json/wp/v2/posts?search=${searchParams.target}`
        );
        const info = await response.json();
        setPosts(info);

        // Get Data from Prismic endpoint by count and type options
        const fetchPrismicData = async () => {
            console.log(searchParams)
            try {
                const res = await client.query(
                    [
                        Prismic.Predicates.at('document.type', 'destinations'),
                        Prismic.Predicates.at('my.destinations.uid', searchParams.target.toLowerCase()),
                        Prismic.Predicates.at('document.tags', searchParams.filterOptions),
                    ],
                    { orderings: '[my.destinations.title]' }
                )
                setIsLoading(false)

                if (res) {
                    setDestinations(res.results);
                }
            } catch (error) {
                console.error(error);
                setIsLoading(false)
            }
        }

        fetchPrismicData();
    };

    const getParamForURL = (url) => {
        let param;
        if (url.includes('civitatis.com')) param = 'aid=2105';
        else if (url.includes('getyourguide.es')) param = 'partner_id=CFD0HQZ';
        else if (url.includes('tiqets.com')) param = 'partner=ardilla.com'
    
        return param;
    }

    return (
        <SearchResultsContainer>
            <MetaTags>
                <title>{'Loop - ' + filterParams?.target} </title>
                <meta id="meta-description" name="description" content= {'Resultados para tu viaje a ' + filterParams?.target} />
                <meta id="og-title" property="og:title" content={'Loop - ' + filterParams?.target} />
                <meta id="og-image" property="og:image" content={defaultimage} />
            </MetaTags>
            <ReactLoaderSpinner isLoading={isLoading} />
            <div>
                {(filterParams && destinations?.length === 0) ? (
                    <>
                        <TopBanner
                            title={filterParams?.target}
                            description="&nbsp;"
                        />
                        {filterParams?.target && (
                            <Morelink>
                                <p>
                                    No encuentras lo que buscas? Mira en esta web amiga &nbsp;
                                    <a className="btn-link" target="_blank"  rel="noreferrer" href={`https://www.civitatis.com/es/${filterParams?.target}/?aid=2105`}>
                                         todo sobre {filterParams?.target}
                                    </a>
                                </p>
                                <p>
                                    También puedes <a className="btn-link" href="/">buscar otro destino</a>
                                </p>
                            </Morelink>
                        )}
                    </>
                ) : (
                    <>
                        {filterParams && destinations.map((item, index) => (
                            <div key={index}>
                                <TopBanner
                                    key={RichText.asText(item.data.title)}
                                    title={RichText.asText(item.data.title)}
                                    description={RichText.asText(item.data.intro)}
                                    image={item.data.featured_image?.url}
                                />
                                <NavFix />
                                <div className="row-wrapper">
                                    <div id="actividades" className="container">
                                        <Rowfull>
                                            <Columnfull>
                                                <h2>Qué hacer en {RichText.asText(item.data.title)}</h2>
                                            </Columnfull>
                                        </Rowfull>

                                        <Rowscroll>
                                    {filterParams.types.includes('relax') && item.data.tipo_relax.map((type, index) => (
                                        <Column key={index}>
                                        <CardResult
                                            key={index}
                                            image={type.tipo_relax_image?.url}
                                            title={type.tipo_relax_titulo[0].text}
                                            link={`${type.tipo_relax_link?.url}?${getParamForURL(type.tipo_relax_link?.url)}`}
                                        />
                                        </Column>
                                    ))}
                                    {filterParams.types.includes('aventura') && item.data.tipo_aventura.map((type, index) => (
                                        <Column key={index}>
                                        <CardResult
                                            key={index}
                                            image={type.tipo_imagen?.url}
                                            title={type?.tipo_titulo[0]?.text}
                                            link={`${type.tipo_enlace?.url}?${getParamForURL(type.tipo_enlace?.url)}`}
                                        />
                                        </Column>
                                    ))}
                                    {filterParams.types.includes('cultural') && item.data.tipo_cultural.map((type, index) => (
                                        <Column key={index}>
                                        <CardResult
                                            key={index}
                                            image={type.tipo_imagen?.url}
                                            title={type?.tipo_titulo[0]?.text}
                                            link={`${type.tipo_enlace?.url}?${getParamForURL(type.tipo_enlace?.url)}`}
                                        />
                                        </Column>
                                    ))}
                                    {filterParams.types.includes('noche') && item.data.tipo_noche.map((type, index) => (
                                        <Column key={index}>
                                        <CardResult
                                            key={index}
                                            image={type.tipo_imagen?.url}
                                            title={type?.tipo_titulo[0]?.text}
                                            link={`${type.tipo_enlace?.url}?${getParamForURL(type.tipo_enlace?.url)}`}
                                        />
                                        </Column>
                                    ))}
                                        </Rowscroll>

                                        <Rowfull>
                                            <Columnfull>
                                                <Morelink>
                                                    <a target="_blank"  rel="noreferrer" href={`https://www.civitatis.com/es/${RichText.asText(item.data.title)}/?aid=2105`}>Ver más</a>
                                                </Morelink>
                                            </Columnfull>
                                        </Rowfull>

                                </div>
                                </div>

                                <div className="row-wrapper grey-bg">
                                    <div id="tours" className="container">
                                        <Rowfull>
                                            <Columnfull>
                                                <h2>Tours y actividades</h2>
                                            </Columnfull>
                                        </Rowfull>
                                        <Rowscroll>
                                            {filterParams.count === 'solo' && item.data.solo_travel.map((count, index) => (
                                                <Column key={index}>
                                                <CardResult
                                                    key={index}
                                                    image={count.image_travel?.url}
                                                    title={count.title_travel[0]?.text}
                                                    link={`${count.link_travel?.url}?${getParamForURL(count.link_travel?.url)}`}
                                                />
                                                </Column>
                                            ))}
                                            {filterParams.count === 'pareja' && item.data.pareja_travel.map((count, index) => (
                                                <Column key={index}>
                                                    <CardResult 
                                                        key={index}
                                                        image={count.image_travel?.url}
                                                        title={count.title_travel[0]?.text}
                                                        link={`${count.link_travel?.url}?${getParamForURL(count.link_travel?.url)}`}
                                                    />
                                                </Column>
                                            ))}
                                            {filterParams.count === 'amigos' && item.data.amigos_travel.map((count, index) => (
                                                <Column key={index}>
                                                    <CardResult 
                                                        key={index}
                                                        image={count.image_travel?.url}
                                                        title={count.title_travel[0]?.text}
                                                        link={`${count.link_travel?.url}?${getParamForURL(count.link_travel?.url)}`}
                                                    />
                                                </Column>
                                            ))}
                                            {filterParams.count === 'familia' && item.data.familia_travel.map((count, index) => (
                                                <Column key={index}>
                                                    <CardResult 
                                                        key={index}
                                                        image={count.image_travel?.url}
                                                        title={count.title_travel[0]?.text}
                                                        link={`${count.link_travel?.url}?${getParamForURL(count.link_travel?.url)}`}
                                                    />
                                                </Column>
                                            ))}
                                        </Rowscroll>

                                        <Rowfull>
                                            <Columnfull>
                                                <Morelink>
                                                    <a target="_blank"  rel="noreferrer" href={`https://www.getyourguide.es/s/?q=${RichText.asText(item.data.title)}&partner_id=CFD0HQZ`}>Ver más</a>
                                                </Morelink>
                                            </Columnfull>
                                        </Rowfull>

                                    </div>
                                </div>

                                <div className="row-wrapper">
                                    <div id="hoteles" className="container">
                                        <Rowfull>
                                            <Columnfull>
                                                <h2>Dónde dormir en {RichText.asText(item.data.title)}</h2>
                                            </Columnfull>
                                        </Rowfull>
                                        <Rowfull>
                                            {item.data.hoteles.map((type, index) => (
                                                <Columnfull className="column50" key={index}>
                                                    <CardResult
                                                        key={index}
                                                        image={type.hotel_image_link[0].text}
                                                        title={type.hotel_name[0].text}
                                                        link={type.hotel_link[0].text + '?aid=1425476'}
                                                    />
                                                </Columnfull>
                                            ))}
                                        </Rowfull>

                                        <Rowfull>
                                            <Columnfull>
                                                <Morelink>
                                                    <a target="_blank"  rel="noreferrer" href={`https://www.booking.com/index.html?aid=1425476`}>Ver más</a>
                                                </Morelink>
                                            </Columnfull>
                                        </Rowfull>

                                    </div>
                                </div>

                                <FlightCars
                                    destination={RichText.asText(item.data.title)}
                                />

                            </div>
                        ))}
                    </>
                )}
                <BlogWrapper>
                    <Rowfull>
                        <Columnfull>
                            <h2 id="posts">Últimos artículos</h2>
                        </Columnfull>
                    </Rowfull>
                    <Rowfull>
                        {posts.slice(0, 8).map((post) => (
                            <BlogItem
                                key={post.id}
                                postImage={post.fimg_url}
                                title={post.title.rendered}
                                btn={`${post.link}`}
                            />
                        ))}
                    </Rowfull>
                </BlogWrapper>
            </div>
            <MainFooter />
        </SearchResultsContainer>
    );
};

export default SearchResults;