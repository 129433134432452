import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import Prismic from "@prismicio/client";
import { RichText } from "prismic-reactjs";
import { client } from "../utils/prismicHelpers";
import CardResult from "./ResultCard";
import Loader from "../components/Loader";
import TopBanner from "./TopBanner";
import FlightCars from "./FlightCars";
import MainFooter from "../components/Footer";
import { bp, btnBlack, color } from "../Styles/styled/general";
import { col, row } from "../Styles/styled/grid";
import BlogItem from "../Blog/BlogItem";
import MetaTags from "react-meta-tags";
import NavFix from "../components/NavFix";


const SearchResultsContainer = styled.div`
  width: 100%;
  .row-wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .count-wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: ${color.lightGrey};
  }
`;
const Columnfull = styled.div`
    ${col}
`;

const Column = styled.div `
    ${col}
    @media all and (max-width:${bp.xs}) {
        width:420px;
        max-width:420px;
        flex-basis:420px;
        flex: 0 0 auto;
    }
    @media all and (min-width:${bp.xs}) {
        flex-basis:100%;
        flex-basis:33.3333%;
    }
    
`;

const Rowscroll = styled.div `
    ${row}
    @media all and (max-width:${bp.xs}) {
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 20px;
        }
`;

const Row = styled.div`
  ${row}
  h2 {
    margin-top: 0.5em;
    margin-bottom: 1.5em;
    text-align: center;
    font-size: 1.6rem;
    @media all and (min-width: ${bp.md}) {
      font-size: 2rem;
    }
  }
`;
const Morelink = styled.div`
  text-align: center;
  margin-top: 3rem;
  p {
    font-size: 1.2rem;
    font-weight: 600;
  }
  a {
    ${btnBlack};
    :hover {
      background: ${color.darkGrey};
      color: #fff;
    }
  }
`;
const Noresult = styled.div`
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  p {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6;
  }
  a {
    text-decoration: underline;
    color: ${color.lightTeal};
    :hover {
    }
  }
`;

const BlogWrapper = styled.div`
  margin-top: 24px;
  padding-left: 0.625em;
  padding-right: 0.625em;
  width: 100%;
  max-width: 1220px;
  margin: auto;
`;
const Rowfull = styled.div`
  ${row};
  h2 {
    margin-top: 0.5em;
    margin-bottom: 1.5em;
    text-align: center;
    font-size: 1.4rem;
    @media all and (min-width: ${bp.md}) {
      font-size: 2rem;
    }
  }
  .column50 {
    @media all and (max-width: ${bp.xxs}) {
      flex-basis: 100%;
      &:first-child {
        padding-bottom: 20px;
      }
    }
  }
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

export default function DestinationPage() {
  const { uid } = useParams();
  const history = useHistory();

  const [wpData, setWPData] = useState([]);
  const [destination, setDestination] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        // Get Blogs from Wordpress endpoint by search target
        const resWP = await fetch(
          `https://elblogdeviajes.com/wp-json/wp/v2/posts?search=${uid}`
        );
        const infoWP = await resWP.json();
        // console.log(infoWP);
        setWPData(infoWP);
        history.push(`/guia/${uid}`, { params: infoWP });
        // Get Prismic Data
        const resPrismic = await client.query(
          Prismic.Predicates.at("my.destinations.uid", uid),
          { lang: "*" }
        );
        setIsLoading(false);

        if (resPrismic.results && resPrismic.results.length !== 0) {
          // console.log(resPrismic.results);
          setDestination(resPrismic.results[0]);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
    fetchData();
  }, [uid]);

  const getParamForURL = (url) => {
    let param;
    if (url.includes("civitatis.com")) param = "aid=2105";
    else if (url.includes("getyourguide.es")) param = "partner_id=CFD0HQZ";
    else if (url.includes("tiqets.com")) param = "partner=elblogdeviajes.com";

    return param;
  };

  return (
    <SearchResultsContainer>
      <MetaTags>
        <title>
          {"Loop - " + RichText.asText(destination?.data?.title) || uid}{" "}
        </title>
        <meta
          id="meta-description"
          name="description"
          content={
            "Si vas a viajar a " + RichText.asText(destination?.data?.title) ||
            uid + "aquí tienes todo lo que necesitas"
          }
        />
        <meta
          id="og-title"
          property="og:title"
          content={"Loop - " + RichText.asText(destination?.data?.title) || uid}
        />
        <meta
          id="og-image"
          property="og:image"
          content={destination?.data?.featured_image?.url}
        />
      </MetaTags>
      <Loader isLoading={isLoading} />
      <div>
        <TopBanner
          title={RichText.asText(destination?.data?.title) || uid}
          description={
            RichText.asText(destination?.data?.intro) || "Sin resultados"
          }
          image={destination?.data?.featured_image?.url}
        />
        {destination?.length === 0 ? (
          <Noresult>
            <p>
              Aún no tengo info sobre <Capitalize>{uid}</Capitalize>
              <br />
              Puedes pedir más info en las redes sociales: @elblogdeviajes.
            </p>
            <p>
              También puedes ver sobre <Capitalize>{uid}</Capitalize> en{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.civitatis.com/es/${uid}/?aid=2105`}
              >
                esta otra web
              </a>
            </p>
          </Noresult>
        ) : (
          <div>
            <NavFix />
            <div className="row-wrapper">
              <div id="actividades" className="container">
                <Row>
                  <Columnfull>
                    <h2>Qué hacer</h2>
                  </Columnfull>
                </Row>
                <Rowscroll>
                  {destination?.data?.tipo_cultural.map((type, index) => (
                    <Column>
                      <CardResult
                        key={index}
                        image={type.tipo_imagen?.url}
                        title={type.tipo_titulo[0]?.text}
                        link={`${type.tipo_enlace?.url}?${getParamForURL(
                          type.tipo_enlace?.url
                        )}`}
                      />
                    </Column>
                  ))}
                </Rowscroll>
                <Row>
                  <Columnfull>
                    <Morelink>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://www.civitatis.com/es/${uid}/?aid=2105`}
                      >
                        Ver más
                      </a>
                    </Morelink>
                  </Columnfull>
                </Row>
              </div>
            </div>

            <div className="row-wrapper">
              <div id="hoteles" className="container">
                <Row>
                  <Columnfull>
                    <h2>
                      Dónde dormir en <Capitalize>{uid}</Capitalize>
                    </h2>
                  </Columnfull>
                </Row>

                <Row>
                  {destination?.data?.hoteles.map((type, index) => (
                    <Columnfull>
                      <CardResult
                        key={index}
                        image={type.hotel_image_link[0].text}
                        title={type.hotel_name[0].text}
                        link={type.hotel_link[0].text + "?aid=1425476"}
                      />
                    </Columnfull>
                  ))}
                </Row>
                <Row>
                  <Columnfull>
                    <Morelink>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://www.booking.com/index.html?aid=1425476`}
                      >
                        Ver más alojamientos
                      </a>
                    </Morelink>
                  </Columnfull>
                </Row>
              </div>
            </div>

            <div className="row-wrapper">
              <div id="tours" className="container">
                <Row>
                  <Columnfull>
                    <h2>Tours y excursiones</h2>
                  </Columnfull>
                </Row>

                <Rowscroll>
                  {destination?.data?.pareja_travel.map((type, index) => (
                    <Column>
                      <CardResult
                        key={index}
                        image={type.image_travel?.url}
                        title={type.title_travel[0]?.text}
                        link={`${type.link_travel?.url}?${getParamForURL(
                          type.link_travel?.url
                        )}`}
                      />
                    </Column>
                  ))}
                </Rowscroll>

                <Row>
                  <Columnfull>
                    <Morelink>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://www.getyourguide.es/s/?q=${uid}&partner_id=CFD0HQZ`}
                      >
                        Ver más
                      </a>
                    </Morelink>
                  </Columnfull>
                </Row>
              </div>
            </div>
            <FlightCars destination={uid} />
          </div>
        )}
        {wpData.length !== 0 && (
          <div className="row-wrapper">
            <div id="posts" className="container">
              <Row>
                <Column>
                  <h2>
                    Artículos de <Capitalize>{uid}</Capitalize>
                  </h2>
                </Column>
              </Row>
              <BlogWrapper>
                <Rowfull>
                  {wpData.map((item, index) => (
                    <>
                      {index < 8 && (
                        <BlogItem
                          key={index}
                          postImage={item.fimg_url}
                          title={item.title.rendered}
                          btn={`${item.link}`}
                        />
                      )}
                    </>
                  ))}
                </Rowfull>
              </BlogWrapper>
            </div>
          </div>
        )}
      </div>
      <MainFooter />
    </SearchResultsContainer>
  );
}
