import React from "react";

const NotFound = () => {
    return (
        <div className="NotFound">
            <p>Página no encontrada - 404</p>
        </div>
    );
};

export default NotFound;