import React from "react";
import ReactGA from "react-ga";
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Switch} from "react-router-dom";
import PrismicScript from "./PrismicScript";
import "./App.css";
import Header from "./components/Header";
import SearchTrip from "./Search/Search";
import SearchResults from "./Search/Results";
import Destination from "./Search/Destination";
import NotFound from "./components/NotFound";


ReactGA.initialize("UA-30222487-1");
ReactGA.pageview(window.location.pathname + window.location.search);
var history = createBrowserHistory();
history.listen((location) => {
    window.ga('set', 'page', location.pathname + location.search);
    window.ga('send', 'pageview');
});

const App = () => {
    return (
        <div className="App">
            <Header />
            <BrowserRouter history={history}>
                <Switch>
                    <Route path="/" exact={true} component={SearchTrip} />
                    <Route path="/guia" exact={true} component={SearchResults} />
                    <Route path="/guia/:uid" component={Destination} />
                    <Route component={NotFound}/>
                </Switch>
            </BrowserRouter>
            <PrismicScript />
        </div>
    );
};

export default App;
