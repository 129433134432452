import React from "react";
import styled from "styled-components";
import {bp} from "../Styles/styled/general";


const Card = styled.article `
     flex-basis:50%;
     position:relative;
     padding: 0 6px;
     img {
        width: 100%;
        vertical-align:bottom;
     }
    @media all and (min-width:${bp.xxs}) {
        flex-basis:33.3333%;
    }
    @media all and (min-width:${bp.md}) {
        flex-basis:25%;
          .imageLink {
        overflow:hidden;
        height: 195px;
        width:100%;
        display: inline-block;
        &:hover {opacity: .85;}
     }
    }
   
`;

const Text = styled.div `
   padding: 0;
   h3 {
   margin-top: 0;
   margin-bottom: 1.5em;
   font-size:.9rem;
   line-height: 1.5;
   }
   .btnLink {
   text-decoration:none;
        &:hover h3 {opacity: .6;}
   }
   @media all and (min-width:${bp.md}) {
        padding: .5em 0;
   }
`;

const BlogItem = ({title, postImage, btn}) => {
    return (
    <Card>
        <a className="imageLink" href={`${btn}`}>
            <img src={postImage} alt={title}/>
        </a>
        <Text>

            <a className="btnLink" href={`${btn}`}><h3>{title}</h3></a>
        </Text>
    </Card>

    )
}

export default BlogItem;
