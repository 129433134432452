import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {bp, btnLink, color} from "../Styles/styled/general";
import travelerImg from "../assets/images/travelers.jpg";
import arrow from "../assets/icons/arrow.svg";
import adventureIcon from "../assets/icons/adventure.svg"
import culturalIcon from "../assets/icons/cultural.svg"
import nightlifeIcon from "../assets/icons/nightlife.svg"
import relaxIcon from "../assets/icons/relax.svg"
import soloIcon from "../assets/icons/solo.svg"
import parejaIcon from "../assets/icons/pareja.svg"
import amigosIcon from "../assets/icons/amigos.svg"
import familiaIcon from "../assets/icons/family2.svg"
import MetaTags from 'react-meta-tags';
import defaultimage from "../assets/images/404a.jpg";


// Styles
const SearchWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background: transparent url(${travelerImg}) no-repeat center center / cover;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    :after {
        content: '';
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background:rgba(0,0,0,.2)
    }
`;

const SearchWrapperInner = styled.div`
    width: 100%;
    max-width: 680px;
    flex-basis: 100%;
    margin: auto;
    z-index: 2;
    padding-left:20px;
    padding-right:20px;
`;

const Intro = styled.div `
    width: 100%;
    max-width: 640px;
    flex-basis: 100%;
    margin-bottom: 2em;

    h1 {
        text-align: left;
        font-size: 4.5em;
        color: #fff;
        text-shadow: 4px 4px 7px rgba(27,27,27,0.25);
        margin-bottom: .15em;
         @media all and (max-width:${bp.xs}) {
            font-size: 3em;
        }
        @media all and (max-width:${bp.xxs}) {
            font-size: 2em;
        }
    }

    p {
        text-align: left;
        font-size: 1.6em;
        color: #fff;
        font-weight: 100;
        @media all and (max-width:${bp.xs}) {font-size: 1.1em;}
    }
`;

const ProgressContainer = styled.div `
    flex-basis: 100%;
    margin-right: auto;
    margin-bottom: 20px;
    position: absolute;
    top: 0;
    left: 0;

    progress {
        width: 640px;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0;
        overflow:hidden;
        height: 8px;
        vertical-align:top;
        ::-webkit-progress-bar {background: ${color.lightGrey}}
        ::-webkit-progress-value {background:${color.darkGreen}}
        @media all and (max-width:${bp.xs}) {
        width:100%;
    }
`;

const FormWrapper = styled.div `
    background: #fff;
    border-radius: 5px;
    padding: 2em 1.5em;
    display: flex;
    flex-wrap: wrap;
    position: relative;
        box-shadow: 0px 0.5rem 0.75rem rgb(0 0 0 / 8%), 0px 0.25rem 3rem rgb(0 0 0 / 8%);
    
        
    form {
    width:100%;
        @media all and (min-width:${bp.xs}) {
          width: calc(100% - 140px);  
        }
    }
        
    button {
        ${btnLink};
        background: ${color.green} url(${arrow}) no-repeat 93% center / 22px;
        border: 0;
        cursor: pointer;
        width: 100%;
        padding: 19px 27px 19px 10px;
        font-family: "Open Sans", sans-serif;
        align-self: flex-end;
        height: auto;
        margin-top: 10px;
        @media all and (min-width: ${bp.xxs}) and (max-width: ${bp.xs}) {
            
        }
        @media all and (min-width: ${bp.xs}) {
            max-width: 140px;
            margin: 0;
        }

        &:hover {
            opacity: .9;
            background-position: 95% center;
        }
    }
`;

const LabelForm = styled.label `
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: .5em;
    text-align: left;
    width: 100%;
    display: block;
    color:#000;
`;

const InputText = styled.input `
    padding: 15px;
    border: 1px solid ${color.darkGrey};
    border-radius: 5px;
    width: 99%;
    font-size: 18px;
    background: #fff;
    color: #000;

    ::placeholder,
    ::-webkit-input-placeholder {
        color:#999;
    
    }
    :-ms-input-placeholder {
        color:#999;
        font-weight: 100;
    }
`;

const CheckboxContainer = styled.div `
    display: inline-block;
    padding: 0 10px;
    input[type=checkbox] {display:none;}
    label span {
        width: 100%;
        margin-top: 75%;
        text-transform: uppercase;
        display: block;
        font-weight: 600;
        color: #666;
        font-size: .8em;
        text-align:center;
    }

    input[type=checkbox] + label {
        display:inline-block;
        padding: 10px;
        border: 1px solid #aeaeae;
        border-radius: 6px;
        background-image:url(${adventureIcon});
        background-repeat: no-repeat;
        height: 90px;
        width: 90px;
        background-size: 50%;
        background-position: center 20%;
        cursor: pointer;
        &:hover {opacity: .8}
        &[for=cultural] {background-image:url(${culturalIcon})}
        &[for=noche] {background-image:url(${nightlifeIcon})}
        &[for=relax] {background-image:url(${relaxIcon})}
    }

    input[type=checkbox]:checked + label {
        border-color: #5fd48f;
        background-color: #eaf8f0;
        span {color:#333}
    }
    @media all and (max-width: ${bp.xxs}) {
        padding: 0 4px;
        width:25%;
        label span {font-size:.7rem}
        input[type=checkbox] + label {
            width:100%;
            height:100%;
        }
    }
    @media all and (max-width: 440px) {
        width:50%;
        padding-top: 4px;
        padding-bottom: 4px;
    }
`;

const RadioContainerWrapper = styled.div `
    input[type=radio] {display:none;}
    label span {
        width: 100%;
        margin-top: 75%;
        text-transform: uppercase;
        display: block;
        font-weight: 600;
        color: #666;
        font-size: .8em;
        text-align:center;
    }

    input[type=radio] + label {
        display:inline-block;
        padding: 10px;
        border: 1px solid #aeaeae;
        border-radius: 6px;
        background-image:url(${soloIcon});
        background-repeat: no-repeat;
        height: 90px;
        width: 90px;
        background-size: 50%;
        background-position: center 20%;
        cursor: pointer;
        &:hover {opacity: .8}
        &[for=option2] {
            background-image:url(${parejaIcon});     
            background-size: 40%;
        }
        &[for=option3] {background-image:url(${amigosIcon})}
        &[for=option4] {background-image:url(${familiaIcon})}
    }

    input[type=radio]:checked + label {
        border-color: #5fd48f;
        background-color: #eaf8f0;
        span {color:#333}
    }
`;
const RadioContainer = styled.div `
    display: inline-block;
    padding: 0 10px;
    @media all and (max-width: ${bp.xxs}) {
        padding: 0 4px;
        width:25%;
        label span {font-size:.7rem}
        input[type=radio] + label {
            width:100%;
            height:100%;
        }
    }
    @media all and (max-width: 440px) {
        width:50%;
        padding-top: 4px;
        padding-bottom: 4px;
    }
`;

const SearchTrip = () => {
    const history = useHistory();

    // const [prismicData, setPrismicData] = useState();
    // const [trips, setTrips] = useState([]);
    // const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState({
        target: "",
        type: "",
        count: "pareja",
    });

    const [typeOptions, setTypeOptions] = useState([
        { type: "aventura", checked: false },
        { type: "cultural", checked: false },
        { type: "noche", checked: false },
        { type: "relax", checked: false },
    ]);

    const [page, setpage] = useState(1);

    useEffect(() => {
        // getTrips();
    }, [typeOptions, page]);


    const handleInput = async (e) => {
        setSearchTerm({
            ...searchTerm,
            [e.target.name]: e.target.value,
        });
    };

    const startSearch = async () => {
        // TO DO search engine
        const types = [];
        const filterOptions = [searchTerm.count];
        typeOptions.filter(type => type.checked).map(filterType => {
            types.push(filterType.type);
            filterOptions.push(filterType.type);
            return 0;
        })        

        const payload = {
            target: searchTerm.target,
            count: searchTerm.count,
            types,
            filterOptions,
        };

        
        // console.log(payload);

        history.push(`/guia`, { params: payload });
    };

    function goNextpage() {
        if (page % 3 === 0) {
            setpage(1);
        } else {
            setpage((page) => page + 1);
        }
    }

    const CheckboxField = ({ label, checked, onChange }) => {
        return (
            <CheckboxContainer>
                <input
                    id={label}
                    type="checkbox"
                    checked={checked}
                    onChange={(ev) => onChange(ev.target.checked)}
                />
                <label htmlFor={label} >
                  <span>{label}</span>
                </label>

            </CheckboxContainer>
        );
    };

    const handleTypeCheckbox = (checked, option) => {
        let options = typeOptions;
        let newOptions = [];
        options.forEach((type) => {
            if (type.type === option.type) {
                type.checked = checked;
                newOptions.push(type);
            } else {
                newOptions.push(type);
            }
        });

        setTypeOptions(newOptions);
    };

    function OnboardingTwo(props) {
        return (
            <div>
                {props.typeValues.map((option) => {
                    return (
                        <CheckboxField
                            key={option.type}
                            label={option.type}
                            checked={option.checked}
                            onChange={(value) => props.updateType(value, option)}
                        />
                    );
                })}
            </div>
        );
    }

    function OnboardingThree() {
        return (
            <RadioContainerWrapper>
               <RadioContainer>
                <input
                    type="radio"
                    id="option1"
                    name="count"
                    value="solo"
                    checked={searchTerm.count === "solo" ? true : false}
                    onChange={handleInput}
                />
                <label htmlFor="option1">
                    <span>Solo</span>
                </label>
               </RadioContainer>
               <RadioContainer>
                <input
                    type="radio"
                    id="option2"
                    name="count"
                    value="pareja"
                    checked={searchTerm.count === "pareja" ? true : false}
                    onChange={handleInput}
                />
                <label htmlFor="option2">
                    <span>Pareja</span>
                </label>
               </RadioContainer>
                <RadioContainer>
                <input
                    type="radio"
                    id="option3"
                    name="count"
                    value="amigos"
                    checked={searchTerm.count === "amigos" ? true : false}
                    onChange={handleInput}
                />
                <label htmlFor="option3">
                    <span>Amigos</span>
                </label>
                </RadioContainer>
            <RadioContainer>
                <input
                    type="radio"
                    id="option4"
                    name="count"
                    value="familia"
                    checked={searchTerm.count === "familia" ? true : false}
                    onChange={handleInput}
                />
                <label htmlFor="option4">
                    <span>Familia</span>
                </label>
            </RadioContainer>
            </RadioContainerWrapper>

        );
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            goNextpage();
        }
    }
    return (
        <SearchWrapper>
            <SearchWrapperInner>
                <MetaTags>
                    <title>{'Loop - by El Blog de Viajes'} </title>
                    <meta id="meta-description" name="description" content= {'El mejor buscador de viajes'} />
                    <meta id="og-title" property="og:title" content={'Loop  - by El Blog de Viajes'} />
                    <meta id="og-image" property="og:image" content={defaultimage} />
                </MetaTags>
                <Intro>
                    <h1>Organiza tu viaje</h1>
                    <p>Construye tu próximo viaje en 3 pasos.</p>
                </Intro>

                <FormWrapper>
                    <ProgressContainer>
                        <progress max="3"value={page}></progress>
                    </ProgressContainer>

                    <form className="search-form">
                        {page === 1 && (
                            <div className="onboard-one">
                                <LabelForm htmlFor="target">
                                    ¿A dónde viajas?
                                </LabelForm>
                                <InputText
                                    id="target"
                                    type="text"
                                    name="target"
                                    placeholder="Escribe tu destino"
                                    value={searchTerm.target}
                                    onChange={handleInput}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>

                        )}
                        {page === 2 && typeOptions && (
                            <OnboardingTwo
                                typeValues={typeOptions}
                                updateType={(value, option) => handleTypeCheckbox(value, option)}
                            />
                        )}
                        {page === 3 && <OnboardingThree />}
                    </form>
                    {page !== (4 && 3)  && <button onClick={goNextpage}>Siguiente</button>}
                    {page === 3 && <button type="button" onClick={() => { startSearch(); goNextpage();}}>Buscar</button>}
                </FormWrapper>


            <div className="searchresults hidden">
                <h2>Resultados</h2>
                <h3>
                    Civitatis Tours:{" "}
                    <a href={`https://www.civitatis.com/es/${searchTerm.target}/?aid=2105`}>
                        {searchTerm.target}
                    </a>
                </h3>
                <h3>
                    GYG Tours:{" "}
                    <a
                        href={`https://www.getyourguide.es/s/?q=${searchTerm.target}/?partner_id=CFD0HQZ`}
                    >
                        {searchTerm.target}
                    </a>
                </h3>
                <h3>
                    Hotels:{" "}
                    <a
                        href={`https://www.booking.com/city/es/${searchTerm.target}.es.html?aid=1425476&no_rooms=1&group_adults=2`}
                    >
                        {searchTerm.target}
                    </a>
                </h3>
                <h3>
                    Blog Posts:{" "}
                    <a href={`https://elblogdeviajes.com/?s=${searchTerm.target}`}>
                        {searchTerm.target}
                    </a>
                </h3>
            </div>
            </SearchWrapperInner>
        </SearchWrapper>
    );
};

export default SearchTrip;
